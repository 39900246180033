<template>
  <b-nav-item @click="changeResponsive()">
    <feather-icon
      size="21"
      :icon="`${responsive ? 'Monitor' : 'Smartphone'}Icon`"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { responsive } = useAppConfig()

    return { responsive }
  },
  methods: {
    changeResponsive() {
      const responsiveHead = 'width=device-width,initial-scale=1.0,maximum-scale=1'
      if (this.responsive) {
        document.querySelector('meta[name="viewport"]').content = ''
        this.responsive = false
      } else {
        document.querySelector('meta[name="viewport"]').content = responsiveHead
        this.responsive = true
      }
    },
  },
}
</script>
